import * as React from 'react';
import {
  Routes,
  Route,
  useLocation,
  Navigate,
  unstable_HistoryRouter as HistoryRouter
} from 'react-router-dom';
import { history } from './app/history';
import { getLoggedInUser } from 'utilities/helpers';
import Login from './features/login';
import Welcome from './features/welcome';
//import Dashboard from './features/dashboard';
import DeclareGift from './features/declare_a_Gift';
import ViewPastGift from './features/view_past_gift';
import DeclareGiftForm from './features/declare_gift_form';
import ViewGiftDetails from './features/view_gift_details';
import RequestGiftYtb from './features/request-gift-ytb';
import GiftReceived_Form from './features/gift_received_form';
import ViewGiftRequest from './features/view_gift_request';
import ViewGiftRequestDetails from './features/view_gift_request_details';
import ViewReturnGift from './features/view_gift_return';
import ViewReceivedGift from './features/view_gift_received';
import ViewGiftReceivedDetails from './features/view_gift_received_details';
import ViewReceivedGift_Request from './features/view_gift_received_request';
import ViewGiftReceivedRequestDetails from './features/view_gift_received_request_details';
import ViewGiftReturnDetails from './features/view_gift_return_details';
import Admin from './features/admin';
import EntryDelegatorFlows from './features/admin/components/adminPage/entry_delegator_flows';
import ReviewEntryDelegatorFlows from './features/admin/components/adminPage/review_Entry_Delegator_flows';
import CreateNewDelegatorFlow from './features/admin/components/adminPage/create_new_delegator_flow';
import CreateNewApprovalDelegatorFlow from './features/admin/components/adminPage/create_new_approval_delegator_flow';
import ReportPage from './features/admin/components/adminPage/reports';
import BulkUpload from './features/bulkUpload/index';
import PendingApprovals from './features/welcome/components/welcomePage/pendingApprovals';
import GiftMasterFlows from './features/admin/components/adminPage/gift_master_flow';
import BuMasterFlows from './features/admin/components/adminPage/bu_master_flow';
import DesignatedAuthoritiesFlows from './features/admin/components/adminPage/designated_Authorities_flow';
import CreateGiftMasterFlow from './features/admin/components/adminPage/gift_master_flow/create_gift_master_flow';
import CreateDesignatedAuthorities from './features/admin/components/adminPage/designated_Authorities_flow/create_designated_authorities';
import CreateBuMsterFlow from './features/admin/components/adminPage/bu_master_flow/create_bu_master_flow';
import LimitMasterFlow from './features/admin/components/adminPage/limit_master_flow';
import UserManagementFlow from './features/admin/components/adminPage/user_Management_flow';
import AssignRoleToUser from 'features/admin/components/adminPage/user_Management_flow/assign_role_to_user';
import AssignBU from 'features/assignBU';
import OneClickPublicAccess from './features/oneClickPublicAccess';
import OneClickReject from './features/oneClickPublicAccess/OneClickReject';
import EditBuMsterFlow from './features/admin/components/adminPage/bu_master_flow/edit_bu_master';
import Dashboard from './features/dashboard';
import ROMasterFlows from './features/admin/components/adminPage/ro_master_flow';
import CreateROMasterFlows from './features/admin/components/adminPage/ro_master_flow/create_ro_master_flow';
import EditROMasterFlows from './features/admin/components/adminPage/ro_master_flow/edit_ro_master';
import RoUsersFlows from './features/admin/components/adminPage/ro_users_flow';
import CreateRoUsersFlows from './features/admin/components/adminPage/ro_users_flow/create_ro_users_flow';
import EditRoUsersFlows from './features/admin/components/adminPage/ro_users_flow/edit_roUsersFlows';
import SeniorManagementFlow from './features/admin/components/adminPage/senior_management_flow';
import EditSeniorManagementFlow from './features/admin/components/adminPage/senior_management_flow/edit_senior_management_flow';
import CEC_Users_Flows from './features/admin/components/adminPage/cec_users_flow';
import Create_CEC_Users from './features/admin/components/adminPage/cec_users_flow/create_cec_users';
import MD_Users_Flows from './features/admin/components/adminPage/md_users_flow';
import Create_MD_Users from './features/admin/components/adminPage/md_users_flow/create_md_users';
import CXO_Users_Flows from './features/admin/components/adminPage/cxo_users_flow';
import Create_CXO_Users from './features/admin/components/adminPage/cxo_users_flow/create_cxo_users';
import GiftGivenLink from './features/dashboard/components/dashboardPage/snapshotCardLink/GiftGivenLink';
import GiftReceivedLink from './features/dashboard/components/dashboardPage/snapshotCardLink/GiftReceivedLink';

import { MsalProvider } from '@azure/msal-react';
import { EventType, PublicClientApplication } from '@azure/msal-browser';
import { useSelector } from 'react-redux';
import Loader from './common/Loader';
import Rejected from './features/welcome/components/welcomePage/rejected';
import Approved from './features/welcome/components/welcomePage/approved';
import ActionRequired from './features/welcome/components/welcomePage/actionRequired';
import Returned from './features/welcome/components/welcomePage/returned';

const configuration = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID,
    authority: process.env.REACT_APP_AUTHORITY,
    redirectUri: 'https://giftregister.tataprojects.com/session.html'
    // clientId: '955d7be1-7035-46b5-b278-bcd7dbe88848',
    // authority:
    //   'https://login.microsoftonline.com/a623a2c9-fed1-4cb4-b31f-ba65fbb95c00',
    //redirectUri: 'https://gr.lexyslabs.com/session.html'
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: true // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    windowHashTimeout: 60000,
    iframeHashTimeout: 60000,
    loadFrameTimeout: 0,
    asyncPopups: false
  }
};
const pca = new PublicClientApplication(configuration);
pca.addEventCallback((event) => {
  // console.log(event);
  if (event.eventType === EventType.LOGIN_SUCCESS) {
    pca.setActiveAccount(event.payload.account);
  }
});
//const pca = new PublicClientApplication(configuration);

const roleBasedNavigation = (id) => {
  switch (id) {
    case 'requester':
      return '/dashboard';
    case 'admin':
      return '/gatepass-requests';
    default:
      return '/';
  }
};

const PrivateRoute = ({ children }) => {
  let location = useLocation();

  const { loading } = useSelector((state) => state.login);
  const { loggedIn } = getLoggedInUser();

  if (loading) {
    return <Loader />;
  }

  if (!loggedIn) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
};

const AuthChecker = ({ children }) => {
  let location = useLocation();
  const { loggedIn, user } = getLoggedInUser();
  let goTo = location.state?.from?.pathname
    ? location.state?.from?.pathname
    : roleBasedNavigation(user?.user_details?.role || '');

  if (loggedIn) {
    return <Navigate to={goTo} state={{ from: location }} />;
  }

  return children;
};

function App() {
  const { user } = getLoggedInUser();

  let goTo = location.state?.from?.pathname
    ? location.state?.from?.pathname
    : user?.user_details?.role
    ? roleBasedNavigation(user?.user_details?.role || '')
    : '/login';

  return (
    <HistoryRouter history={history}>
      <Routes>
        <Route path="/" element={<Navigate replace to={goTo} />} />
        <Route path="quick-access" element={<OneClickPublicAccess />} />
        <Route
          path="one-click-public-access"
          element={<OneClickPublicAccess />}
        />
        <Route path="one-click-reject" element={<OneClickReject />} />
        <Route
          path="login"
          element={
            <AuthChecker>
              <MsalProvider instance={pca}>
                <Login />
              </MsalProvider>
            </AuthChecker>
          }
        />
        <Route
          path="assign-BU"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <AssignBU />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="welcome"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <Welcome />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="dashboard"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <Dashboard />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="declare-a-gift"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <DeclareGift />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="view-past-gift"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <ViewPastGift />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="view-gift-requests"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <ViewGiftRequest />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="declare-gift-form"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <DeclareGiftForm />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="view-gift-details/:id"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <ViewGiftDetails />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="request-gift-ytb"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <RequestGiftYtb />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="declare-gift-received"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <GiftReceived_Form />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="view-gift-request-details/:id"
          element={
            /*  <ViewGiftRequestDetails /> */
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <ViewGiftRequestDetails />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="view-gift-returns"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <ViewReturnGift />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="view-gift-received"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <ViewReceivedGift />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="view-gift-received-details/:id"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <ViewGiftReceivedDetails />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="view-gift-received-request"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <ViewReceivedGift_Request />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="view-gift-received-request-details/:id"
          element={
            /*  <ViewGiftReceivedRequestDetails /> */
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <ViewGiftReceivedRequestDetails />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="view-gift-return-details/:id"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <ViewGiftReturnDetails />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="admin"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <Admin />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="reports"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <ReportPage />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="entry-delegator-flows"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <EntryDelegatorFlows />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="review-entry-delegator-flows"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <ReviewEntryDelegatorFlows />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="create-new-delegator-flow"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <CreateNewDelegatorFlow />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="create-new-approval-delegator-flow"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <CreateNewApprovalDelegatorFlow />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="gift-master-flow"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <GiftMasterFlows />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="bu-master-flow"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <BuMasterFlows />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="designated-authorities-flow"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <DesignatedAuthoritiesFlows />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="cec-users-flow"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <CEC_Users_Flows />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="create_cec_users"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <Create_CEC_Users />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="md-users-flow"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <MD_Users_Flows />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="create_md_users"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <Create_MD_Users />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="cxo-users-flow"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <CXO_Users_Flows />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="create_cxo_users"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <Create_CXO_Users />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="create-bu-master-flow"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <CreateBuMsterFlow />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="edit-bu-master-flow/:id"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <EditBuMsterFlow />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="create_designated_authorities"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <CreateDesignatedAuthorities />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="create_gift_master_flow"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <CreateGiftMasterFlow />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="limit_master_flow"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <LimitMasterFlow />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="user_management_flow"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <UserManagementFlow />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="ro-master-flow"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <ROMasterFlows />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="create-ro-master-flow"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <CreateROMasterFlows />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="edit-ro-master-flow/:id"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <EditROMasterFlows />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="roUsers-flow"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <RoUsersFlows />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="create-roUsers-flow"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <CreateRoUsersFlows />
              </MsalProvider>
            </PrivateRoute>
          }
        />

        <Route
          path="edit-roUsers-flow/:id"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <EditRoUsersFlows />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="senior-management-flow"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <SeniorManagementFlow />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="edit-senior-management-flow/:id"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <EditSeniorManagementFlow />
              </MsalProvider>
            </PrivateRoute>
          }
        />

        <Route
          path="assign-role-to-user"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <AssignRoleToUser />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="bulk-upload"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <BulkUpload />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="pending-approvals"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <PendingApprovals />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="action-required"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <ActionRequired />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="approved"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <Approved />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="rejected"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <Rejected />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="returned"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <Returned />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="adminDashboard-giftGiven-link"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <GiftGivenLink />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="adminDashboard-giftReceived-link"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <GiftReceivedLink />
              </MsalProvider>
            </PrivateRoute>
          }
        />
      </Routes>
    </HistoryRouter>
  );
}

export default App;
